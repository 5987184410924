import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

const BlogIndex = ({
  data
}) => {
  const posts = data.allWpPost.nodes
  const news = data.MyQueryAllNewPosts.edges
  const categoriesParent = data.MyQueryAllCategoriesParent.nodes
  const categoriesChildren = data.MyQueryAllCategoriesChildren.nodes
  let postsList = [...posts]
  postsList.splice(0, 3)
  
  const { title, description } = data.wp.generalSettings
  const yearNow= new Date().getFullYear()
  const renderCategoryChildren = (id) => {
    let arrChildren: any = [];
    let arrCategoriesChildren = [...categoriesChildren]
    arrCategoriesChildren.forEach(item => {
      if (item.parentId === id) {
        arrChildren.push(item)
      }
    })
    return arrChildren;
  }
  const generateLinkImage = (url) => {
    const image = getImage(url?.gatsbyImage)
    if (url?.mediaItemUrl) {
      return <GatsbyImage className="gatsby-image" image={image} alt={url?.title} />

    } else {
      return (
        <StaticImage
          src="../images/default-image.png"
          alt="default-image"
          className="gatsby-image"
        />
      )

    }
  }

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo
          metaTitle={title}
          metaDescription={process.env.GATSBY_SITE_DESCRIPTION}
          canonical={process.env.GATSBY_SITE_URL}
          image={`${process.env.GATSBY_SITE_URL}/default-image.png`}
        />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }
  function timeDifference(previous) {
    let current = new Date()
    let dt = new Date(previous)
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;
    var elapsed = current - dt;
    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + ' giây trước';
    }
    else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + ' phút trước';
    }

    else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + ' giờ trước';
    }
    else if (elapsed < msPerMonth) {
      if ((elapsed / msPerDay) > 7) {
        return Math.round(elapsed / msPerDay / 7) + ' tuần trước';
      }
      else {
        return Math.round(elapsed / msPerDay) + ' ngày trước';
      }
    }
    else if (elapsed < msPerYear) {
      return Math.round(elapsed / msPerMonth) + ' tháng trước';
    }
    else {
      return Math.round(elapsed / msPerYear) + ' năm trước';
    }
  }
  return (
    <Layout isHomePage>
      <Seo
        metaTitle={title}
        metaDescription={process.env.GATSBY_SITE_DESCRIPTION}
        canonical={process.env.GATSBY_SITE_URL}
        image={`${process.env.GATSBY_SITE_URL}/default-image.png`}
      />

      <div className="category-page__highlight__section bg-color">
        <div className="container highlight__section">
          <div className="category-page__header">
            {/* <div className="category-page__header__title">
              <h1>Địa chỉ chuyên gia cho mẹo vệ sinh và chăm sóc gia đình</h1>
            </div>
            <div className="category-page__header__description">
              <p>Nhà là "Tổ ấm", là nơi trở về sau những mệt mỏi và bộn bề cuộc sống. Hiểu được điều đó, Cleanipedia là nơi cung cấp cho bạn thông tin, mẹo vặt chăm sóc, vệ sinh nhà cửa luôn sạch sẽ, thư giãn và ấm áp. Đặc biệt, khi dịch bệnh Covid lại “hoành hành”, hãy cùng Cleanipedia phòng chống, bảo vệ gia đình bằng cách áp dụng các cách làm sạch, khử khuẩn nhà cửa để cùng nhau vượt qua đại dịch này nhé.</p>
            </div> */}
          </div>
          <div className="category-page__highlight__post__container">
            <div className="category-page__highlight__post post__large">
              <div className="category-page__highlight__post__large__image">
                <Link to={posts[0].uri} itemProp="url">
                  {generateLinkImage(posts[0].seo?.opengraphImage)}
                </Link>
              </div>
              <div className="category-page__highlight__post__large">
                <Link to={posts[0].uri} itemProp="url">
                  <p className="category-page__highlight__post__large__title" itemProp="headline">{parse(posts[0].title)}</p>
                </Link>
                <Link to={`${posts[0]?.categories?.nodes[0]?.slug}/ `} itemProp="url" className="category-page__more__post__item__link">
                  <p className="style-tag">{parse(posts[0]?.categories?.nodes[0]?.name || ``)}</p>
                </Link>
              </div>
            </div>
            <div className="category-page__highlight__post post__small__box">
              {
                [...posts].slice(1, 3).map((post, index) => (
                  <article
                    key={index}
                    className="category-page__highlight__post__small__item"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    <div className="category-page__highlight__post__small__item__image">
                      <Link to={post.uri} itemProp="url">
                        {generateLinkImage(post.seo?.opengraphImage)}
                      </Link>
                    </div>
                    <div className="category-page__highlight__post__small__item__content">
                      <Link to={post.uri} itemProp="url">
                        <p className="category-page__highlight__post__small__item__content__title" itemProp="headline">{parse(post.title)}</p>
                      </Link>
                      <Link to={`${post?.categories?.nodes[0]?.slug}/ `} itemProp="url" className="category-page__more__post__item__link">
                        <p className="style-tag">{parse(post?.categories?.nodes[0]?.name || ``)}</p>
                      </Link>
                    </div>
                  </article>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      {/* <div className="category-page__more__section">
        <div className="container more__section">
          <h2 className="category-page__more__section__title">
            <span>Đọc Tiếp</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19"><path d="M13.235 10.185c-5.434.855-5.955 1.548-6.598 8.772-.643-7.224-1.164-7.917-6.597-8.772 5.433-.855 5.954-1.548 6.597-8.771.643 7.223 1.164 7.916 6.598 8.771zm1.725-6.289c-2.386.376-2.615.68-2.898 3.853-.282-3.173-.511-3.478-2.898-3.853 2.387-.376 2.616-.68 2.898-3.853.283 3.173.512 3.477 2.898 3.853z" fill="#424242"></path></svg>
          </h2>
          <div className="category-page__more__post__container">
            {
              postsList.map((post, index) => (
                <div key={index} className="category-page__more__post__item">
                  <div className="category-page__more__post__item__image">
                    <Link to={post.uri} itemProp="url">
                      <img 
                        src={generateLinkImage(post.seo?.opengraphImage?.mediaItemUrl || `${process.env.GATSBY_SITE_URL}/default-image.jpg`)} 
                        alt={post.title || `${process.env.GATSBY_SITE_NAME}`} 
                        placeholder="blurred" 
                        loading="lazy" 
                      />
                    </Link>
                  </div>
                  <div className="category-page__more__post__item__content">
                    <Link to={post.uri} itemProp="url">
                      <p className="category-page__more__post__item__content__title" itemProp="headline">{parse(post.title)}</p>
                    </Link>
                    <Link to={`${post?.categories?.nodes[0]?.slug}/ `} itemProp="url" className="category-page__more__post__item__link">
                      <p className="style-tag">{parse(post?.categories?.nodes[0]?.name || ``)}</p>
                    </Link>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div> */}
      <div className="row home">
        <div className="col-left desktop">
          {
            [...news].map((item, index) => (
              <div key={index} className="category-page__more__post__item">
                <div className="category-page__more__post__item__image">
                  <Link to={item.post.uri} itemProp="url">
                    <div className="category-highlight-news">
                      <h3 className="category-highlight-news-title"
                        dangerouslySetInnerHTML={{ __html: item.post.title }}
                      ></h3>
                      <div className="category-highlight-news-item">
                        {generateLinkImage(item.post.seo?.opengraphImage)}                       
                        <div className="category-highlight-news-content" >
                          <div className="category-highlight-news-content-item"
                            dangerouslySetInnerHTML={{ __html: item.post.excerpt }}
                          >

                          </div>
                          <div className="category-highlight-news-time">
                            <i className="fa-regular fa-clock" style={{ color: "#999999" }}></i>
                            <p>{timeDifference(item.post.date)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))

          }
        </div>
        <div className="col-left mobile">
          <h2 className="category-page__more__section__title">
            <span>Bài viết mới nhất về Mẫu nail - Nail Hot Trend {yearNow}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19"><path d="M13.235 10.185c-5.434.855-5.955 1.548-6.598 8.772-.643-7.224-1.164-7.917-6.597-8.772 5.433-.855 5.954-1.548 6.597-8.771.643 7.223 1.164 7.916 6.598 8.771zm1.725-6.289c-2.386.376-2.615.68-2.898 3.853-.282-3.173-.511-3.478-2.898-3.853 2.387-.376 2.616-.68 2.898-3.853.283 3.173.512 3.477 2.898 3.853z" fill="#424242"></path></svg>
          </h2>
          {
            [...news].map((item, index) => (
              <div key={index} className="category-page__more__post__item">
                <div className="category-page__more__post__item__image">
                  <Link to={item.post.uri} itemProp="url">
                    <div className="category-highlight-news">
                      <h3 className="category-highlight-news-title"
                        dangerouslySetInnerHTML={{ __html: item.post.title }}
                      ></h3>
                      <div className="category-highlight-news-item">
                        <div className="category-highlight-news-content-item"
                          dangerouslySetInnerHTML={{ __html: item.post.excerpt }}
                        >
                        </div>

                        <div className="category-highlight-news-content" >
                          <div className="category-highlight-news-time">
                            <i className="fa-regular fa-clock icon-time" ></i>
                            <p>{timeDifference(item.post.date)}</p>
                          </div>
                          {generateLinkImage(item.post.seo?.opengraphImage)} 
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))

          }
        </div>
        <div className="col-right desktop">
          {
            categoriesParent.map((item, index) => (
              <div key={index} className="category-page__more__post__item">
                <div className="category-highlight">
                <h2 className="category-highlight-active">
                  <Link to={item.link} itemProp="url">
                    {item.name}
                  </Link>
                  </h2>
                  {renderCategoryChildren(item.id).length > 0 && renderCategoryChildren(item.id).slice(0, 4).map(item => (
                    <h2 className="category-highlight-children">
                    <Link to={item.link} itemProp="url">
                      {item.name}
                    </Link>
                    </h2>
                  ))
                  }
                </div>
                <Link to={item.posts?.nodes[0]?.uri} itemProp="url">
                  <div className="category-highlight-item">
                    {generateLinkImage(item.posts?.nodes[0]?.seo?.opengraphImage)}                   
                    <div className="category-highlight-item-content">
                      <h4>{item.posts?.nodes[0]?.title}</h4>
                      <div className="category-highlight-item-content-excerpt"
                        dangerouslySetInnerHTML={{ __html: item.posts?.nodes[0]?.excerpt }}
                      ></div>
                    </div>
                  </div>
                </Link>
                <div className="category-highlight-news-time">
                  <i className="fa-regular fa-clock" style={{ color: "#999999" }}></i>
                  <p>{timeDifference(item.posts?.nodes[0]?.date)}</p>
                </div>
                <div className="category-highlight-sub-item">
                  {item.posts?.nodes.slice(1, 3).map(elm => (
                    <Link to={elm.uri} itemProp="url"   className="category-highlight-sub-item-name">
                      <div className="more-title"
                        dangerouslySetInnerHTML={{ __html: elm.title }}
                      >
                      </div>
                    </Link>
                  ))}
                </div>

              </div>
            ))
          }

        </div>
        <div className="col-right category-mobile">
          {
            categoriesParent.map((item, index) => (
              <div key={index} className="category-page__more__post__item">
                <div className="category-highlight">
                <h2 className="category-highlight-active">
                  <Link to={item.link} itemProp="url">
                    {item.name}
                  </Link>
                  </h2>
                  {renderCategoryChildren(item.id).length > 0 && renderCategoryChildren(item.id).slice(0, 1).map(item => (
                     <h2 className="category-highlight-children">
                    <Link to={item.link} itemProp="url">
                     {item.name}
                    </Link>
                    </h2>
                  ))
                  }
                </div>
                <Link to={item.posts?.nodes[0]?.uri} itemProp="url">
                  <div className="category-highlight-item">
                    <div className="category-highlight-news-time">
                      <i className="fa-regular fa-clock icon-time" ></i>
                      <p>{timeDifference(item.posts?.nodes[0]?.date)}</p>
                    </div>
                    {generateLinkImage(item.posts?.nodes[0]?.seo?.opengraphImage)}
                    <div className="category-highlight-item-content">
                      <h4>{item.posts?.nodes[0]?.title}</h4>
                      <div 
                        dangerouslySetInnerHTML={{ __html: item.posts?.nodes[0]?.excerpt }}
                      ></div>
                    </div>
                  </div>
                </Link>
                <div className="category-highlight-second-item">
                  <Link to={item.posts?.nodes[1]?.uri} itemProp="url">
                    <div className="category-highlight-second-news">
                      <div className="category-highlight-second-news-title"
                        dangerouslySetInnerHTML={{ __html: item.posts?.nodes[1]?.title }}
                      ></div>
                      <div className="category-highlight-second-news-item">
                        <div className="category-highlight-second-news-content" >
                          <div className="category-highlight-second-news-content-item"
                            dangerouslySetInnerHTML={{ __html: item.posts?.nodes[1]?.excerpt }}
                          >
                          </div>
                        </div>
                        {generateLinkImage(item.posts?.nodes[1]?.seo?.opengraphImage)}
                        
                      </div>
                      <div className="category-highlight-second-news-time">
                        <i className="fa-regular fa-clock" style={{ color: "#999999" }}></i>
                        <p>{timeDifference(item.posts?.nodes[1]?.date)}</p>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="category-highlight-sub-item">
                  {item.posts?.nodes.slice(2, 4).map(elm => (
                    <Link to={elm.uri} itemProp="url" className="category-highlight-sub-item-name">
                      <div className="more-title"
                        dangerouslySetInnerHTML={{ __html: elm.title }}
                      >
                      </div>
                    </Link>
                  ))}
                </div>

              </div>

            ))
          }

        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $customSizePerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $customSizePerPage
      skip: $offset
    ) {
      nodes {
        uri
        title
        seo {
          opengraphImage {
            title
            mediaItemUrl
            gatsbyImage(placeholder: BLURRED, formats: WEBP, width: 800)
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }
    wp {
      generalSettings {
        title
        description
      }
    }
    MyQueryAllNewPosts:
    allWpPost(sort: {fields: [date], order: DESC}, limit: 8,skip:3) {
      edges {
        post: node {
          id
          uri
          title
          date
          excerpt
          seo {
            opengraphImage {
              title
              mediaItemUrl
              gatsbyImage(placeholder: BLURRED, formats: WEBP, width: 800)
            }
          }
        }
      }
    }
    MyQueryAllCategoriesParent:
    allWpCategory(
      filter: {parentId: {eq: null}, posts: {nodes: {elemMatch: {id: {ne: null}}}}}
      limit: 5
    ) {
      nodes {
        id
        name
        link
        posts {
          nodes {
            date
            title
            uri
            excerpt
            seo {
              opengraphImage {
                title
                mediaItemUrl
                gatsbyImage(placeholder: BLURRED, formats: WEBP, width: 800)
              }
            }
          }
        }
      }
    }
    MyQueryAllCategoriesChildren:
    allWpCategory(
      filter: {parentId: {ne: null}}
    ) {
      nodes {
        parentId
        name
        link
      }
    }
  }
 
`
